import '../styles/globals.scss'
import Head from 'next/head'
import * as Sentry from '@sentry/nextjs'
import { SessionProvider, useSession } from 'next-auth/react'
import { ThemeProvider } from '@mui/material'
import NextNProgress from 'nextjs-progressbar'
import theme from '../theme'
import appConfig from '../config/app';
import sessionstorage from 'sessionstorage';
import { useEffect } from 'react';
import { destroyAutoLogout, initializeAutoLogout } from '../helpers/auto_logout';
import ErrorBoundary from '@/components/ErrorBoundry'
import editorState from '@/hook_states/bone_editor'
import { alertErrorOnline } from '@/services/alert'
// import { Logtail } from '@logtail/browser'

// let logtail
if ( process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_LOGTAIL_SOURCE_TOKEN ) {
  // logtail = new Logtail(process.env.NEXT_PUBLIC_LOGTAIL_SOURCE_TOKEN)

  const consoleError = console.error
  console.error = (error, ...optionalParams) => {
    let message = error.message || error.toString().slice(0, 100)
    let editorDetails = {}
    try {
      const pathname = window?.location?.pathname || ""
      if ( pathname.startsWith('/cases/') || pathname.startsWith('/bone_editor/') ) {
        editorDetails = {
          caseType: editorState.caseType.value,
          selectedLayer: editorState.selectedLayer.value,
          selectedUuid: editorState.selectedMesh?.current?.modelUuid?.value,
          activeTools: editorState.tools.active.value,
        }
      }
    } catch(e) {
    }
    consoleError(message, error, ...optionalParams)
    if ( !message?.startsWith('The above error occurred in') ) {
      alertErrorOnline({ message, error, editorDetails, ...optionalParams})
      // logtail.error(message, error, editorDetails, ...optionalParams)
    }
  }
}
if (process.env.NEXT_PUBLIC_NOT_SHOW_CONSOLE) {
  console.log = () => {}
  console.warn = () => {}
}

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  useEffect(() => {
    const errorHandler = ({ message, error }) => {
      if ( process.env.NODE_ENV === 'production' ) {
        let editorDetails = {}
        const pathname = window?.location?.pathname || ""
        if ( pathname.startsWith('/cases/') || pathname.startsWith('/bone_editor/') ) {
          editorDetails = {
            caseType: editorState.caseType.value,
            selectedLayer: editorState.selectedLayer.value,
            selectedUuid: editorState.selectedMesh?.current?.modelUuid?.value,
            activeTools: editorState.tools.active.value,
          }
        }
        if ( !message?.startsWith('The above error occurred in') ) {
          alertErrorOnline({ message, error, editorDetails})
          // logtail.error(message, error, editorDetails)
        }
      }
      return true
    }
    window.addEventListener('error', errorHandler)
    return () => {
      window.removeEventListener('error', errorHandler)
    }
  })

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <ErrorBoundary>
        <SessionProvider session={session}>
          <SessionSaver>
            <ThemeProvider theme={theme}>
              <NextNProgress color={theme.palette.darkRed.main} />
              <Component {...pageProps} />
            </ThemeProvider>
          </SessionSaver>
        </SessionProvider>
      </ErrorBoundary>
    </>
  )
}

export function SessionSaver({ children }) {
  const { data: session, status } = useSession()

  useEffect(() => {
    if ( status === 'authenticated' ) {
      initializeAutoLogout()

      return destroyAutoLogout
    }
  }, [status])

  if ( status === 'loading' ) {
    return false;
  } else if ( status === 'authenticated' ) {
    if ( !sessionStorage.getItem('jwt') ) {
      sessionstorage.setItem('jwt', session.jwt)
    }
  }

  return children
}

export default MyApp

import { hookstate } from '@hookstate/core';
import defaultSettings from '../components/shared/settings/default'

export const getDefaultEditorState = () => ({
  scene: false,

  id: '',
  type: 'ukf',
  caseType: null,
  model: {},
  camera: 'orthographic', // | 'orthographic'

  // Current active control
  // orbit | select
  control: 'orbit',
  mode: 'layers',
  history: [],

  tools: {
    expanded: false,
    active: 'select',
    params: {},
    mode: 'layers',
  },

  layers: {},
  layerFilledWithGeom: false,
  csgResult: null,
  mini: false,

  selectedMesh: '',
  selectedLayer: null,
  selectedLayerModelIndex: null,
  selectedObject: null,

  brpLocation: {
    y: null,
    z: null,
  },
  rotation: null,

  settings: defaultSettings,

  reduction_surface_data: null,
  ukf_cylinders: null,

  isCameraMoving: false,
  scallopCutGeometry: null,
  enableZoom: true,
  tabNavMember: [],
  qcErrors: [],
  abutmentMessages: []
});


const editorState = hookstate(
  getDefaultEditorState()
);

export const resetEditorState = () => {
  if ( editorState.scene.value ) {
    editorState.set(getDefaultEditorState());
  }
};

export default editorState;
